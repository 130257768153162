import React from "react"
import Button from "../../atoms/Button"

const LetsTalk = () => {
  return (
    <section className="py-16 md:py-24 bg-dark">
      <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
        <div className="text-center">
          <h3 className="text-h0/m lg:text-hero/m text-beige">
            Unsure about the best tech to use?
          </h3>
          <div className="text-beige text-base md:text-xl font-semibold mt-10 max-w-2xl xl:px-6 text-center mx-auto">
            Get in touch, and together with you, we’ll make sure you are geared
            and bundled up right to reach the peak you’re seeking.
          </div>
          <div className="mt-16">
            <Button
              variant="contained"
              small="small"
              fullWidthMobile
              href="/contact"
            >
              Let's talk
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LetsTalk
