import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Svg from "react-inlinesvg"
import Slider from "react-slick"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/atoms/Button"
import Heading from "../components/atoms/Heading"
import SubHeader from "../components/atoms/SubHeader"
import ServicesWorkBox from "../components/molecules/ServicesWorkBox"
import ServiceQuoteSection from "../components/organisms/ServiceQuoteSection"
import Brands from "../components/organisms/Brands"
import AuthorisedVendor from "../components/organisms/AuthorisedVendor"
import Testimonials from "../components/organisms/Testimonials"
import LetsTalk from "../components/organisms/LetsTalk"
import DiagonalArrowIcon from "../images/icons/diagonal-arrow.svg"

export const query = graphql`
  query ($slug: String!) {
    technologiesJson(slug: { eq: $slug }) {
      name
      icons {
        name
        icon {
          name
          publicURL
        }
      }
      metaTitle
      metaDescription
      title
      lead
      slug
      description {
        title
        subtitle
        lead
        features {
          name
          desc
        }
      }
      technologies {
        title
        subtitle
        lead
        features {
          name
          desc
        }
      }
      expertise {
        title
        subtitle
        lead
        approachHead
        approach {
          title
          desc
        }
        toolsHead
        tools {
          title
          desc
        }
      }
      works {
        title
        lead
        url
        image {
          id
          childImageSharp {
            gatsbyImageData(width: 720, layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
      quote {
        text
        image {
          id
          childImageSharp {
            gatsbyImageData(width: 1000, layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
      featuredImage {
        absolutePath
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 1200)
        }
      }
    }
  }
`

const Technology = ({ data }) => {
  const technology = data.technologiesJson

  const carouselSettings = {
    arrows: false,
    dots: false,
    swipeToSlide: true,
    infinite: true,
    draggable: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 768,
        settings: {
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "12%",
        },
      },
    ],
  }

  return (
    <Layout
      headerBg="bg-dark"
      logoColor="text-beige"
      headerLinksColor="sm:text-beige"
    >
      <Seo
        title={technology.metaTitle}
        description={technology.metaDescription}
        metaFeaturedImage={
          technology.featuredImage.childImageSharp.gatsbyImageData
        }
      />
      <section className="bg-dark pt-16 pb-10 md:pb-20">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <div className="flex -mx-4">
            <div className="w-full md:w-3/4 lg:w-5/6 px-4">
              <div className="relative z-10">
                {technology.icons && (
                  <div className="flex flex-wrap mt-2">
                    {technology.icons.map(technology => {
                      return (
                        <div
                          key={technology.icon.name}
                          className="inline-flex flex-col items-center mr-6"
                        >
                          <div className="inline-flex items-center justify-center bg-beige rounded-lg shadow-[0_4px_12px_rgba(0,0,0,0.15)] w-12">
                            <Svg
                              src={`${technology.icon.publicURL}`}
                              width="48"
                              height="48"
                              title={technology.icon.name}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
                <h1 className="text-h1/m lg:text-h1 text-beige md:whitespace-pre-line mt-8">
                  {technology.title}
                </h1>
                <div className="flex flex-col-reverse md:flex-col mt-8 md:mt-10">
                  <div className="md:text-base text-beige mt-10 md:mt-0 xl:pr-56">
                    {technology.lead}
                  </div>
                  <div className="mt-12 md:mt-14 md:mb-2">
                    <Button
                      variant="contained"
                      small="small"
                      fullWidthMobile
                      href="/contact"
                    >
                      Let's talk
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 md:py-20 bg-beige">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <SubHeader label={technology.description.subtitle} />
          <div className="mt-2">
            <Heading variant="h2" classes="md:whitespace-pre-line">
              {technology.description.title}
            </Heading>
          </div>
          <div className="text-beige-160 mt-8 lg:mt-10 max-w-2xl whitespace-pre-line">
            {technology.description.lead}
          </div>
          <div className="mt-4 md:mt-8">
            <div className="flex flex-wrap -mx-4 lg:-mx-6">
              {technology.description.features.map(feature => {
                return (
                  <div
                    key={feature.name}
                    className="px-4 lg:px-6 w-full sm:w-1/2 md:w-1/3 mt-8 md:mt-16 mb-2"
                  >
                    <Heading
                      variant="h4"
                      classes="font-extrabold md:whitespace-pre-line mb-4"
                    >
                      <span className="text-primary">{`_ `}</span>
                      {feature.name}
                    </Heading>
                    <div className="pt-0.5">{feature.desc}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 md:py-20 bg-dark">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <div className="text-beige">
            <SubHeader label={technology.technologies.subtitle} />
          </div>
          <div className="lg:mt-2">
            <Heading variant="h2" classes="md:whitespace-pre-line text-beige">
              {technology.technologies.title}
            </Heading>
          </div>
          <div className="text-beige mt-10 max-w-2xl whitespace-pre-line">
            {technology.technologies.lead}
          </div>
          <div className="mt-4 md:mt-8">
            <div className="flex flex-wrap -mx-4 lg:-mx-6">
              {technology.technologies.features.map((feature, index) => {
                return (
                  <div
                    key={feature.name}
                    className="px-4 lg:px-6 w-full sm:w-1/2 md:w-1/3 mt-8 md:mt-16 mb-2"
                  >
                    <Heading
                      variant="h4"
                      classes="font-extrabold text-beige md:whitespace-pre-line mb-4"
                    >
                      {`0${index + 1}`}
                      <span className="text-primary">{` _ `}</span>
                      {feature.name}
                    </Heading>
                    <div className="pt-0.5 text-beige whitespace-pre-line">
                      {feature.desc}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 md:py-20 bg-beige border-b-[16px] border-primary">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <SubHeader label={technology.expertise.subtitle} />
          <div className="lg:mt-6">
            <Heading variant="h2" classes="md:whitespace-pre-line">
              {technology.expertise.title}
            </Heading>
          </div>
          <div className="text-beige-160 mt-8 lg:mt-10 max-w-2xl whitespace-pre-line">
            {technology.expertise.lead}
          </div>
          <div className="bg-white py-8 md:py-14 -mx-4 mt-10 md:mt-16">
            <div className="px-4 lg:px-6">
              <h3 className="font-bold text-sm md:text-xl text-black mb-6">
                {technology.expertise.approachHead}
              </h3>
              <div className="flex flex-wrap -mx-4">
                {technology.expertise.approach.map(item => {
                  return (
                    <div
                      key={item.title}
                      className="w-full sm:w-1/2 md:w-1/3 px-4 lg:mt-8"
                    >
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <DiagonalArrowIcon
                            className="stroke-primary mr-2"
                            width="26px"
                            height="26px"
                            title="Arrow Icon"
                          />
                        </div>
                        <div>
                          <Heading
                            variant="h3"
                            component="h4"
                            classes="font-extrabold mt-2 md:whitespace-pre-line"
                          >
                            {item.title}
                          </Heading>
                          <p className="text-dark font-semibold max-w-[300px] md:whitespace-pre-line">
                            {item.desc}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="md:py-4 -mx-4 mt-10 md:mt-16">
            <div className="px-6">
              <h3 className="font-bold text-sm md:text-xl text-black mb-6">
                {technology.expertise.toolsHead}
              </h3>
              <div className="flex flex-wrap -mx-4">
                {technology.expertise.tools.map(item => {
                  return (
                    <div className="w-full sm:w-1/2 md:w-1/3 px-4 lg:mt-8">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <DiagonalArrowIcon
                            className="stroke-primary mr-2"
                            width="26px"
                            height="26px"
                            title="Arrow Icon"
                          />
                        </div>
                        <div>
                          <Heading
                            variant="h3"
                            component="h4"
                            classes="font-extrabold mt-2 md:whitespace-pre-line"
                          >
                            {item.title}
                          </Heading>
                          <p className="text-dark font-semibold max-w-[300px] md:whitespace-pre-line">
                            {item.desc}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <LetsTalk />

      <section className="bg-beige py-8">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <div className="pt-10">
            <SubHeader label="Works" />
            <Heading variant="h2">
              Tread along our most recently fashioned paths
            </Heading>
            <div className="-mx-3 md:mx-0 mt-12">
              <div className="w-full works-carousel">
                <Slider {...carouselSettings}>
                  {technology.works.map((work, index) => {
                    return (
                      <div key={index} className="md:w-1/3 px-3">
                        <ServicesWorkBox
                          title={work.title}
                          lead={work.lead}
                          url={work.url}
                        >
                          <GatsbyImage
                            image={getImage(work.image)}
                            quality={100}
                            alt={`${work.title} App created by Peak11 Software House`}
                          />
                        </ServicesWorkBox>
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testimonials
        header="Your journey with Peak11"
        subHeader="Testimonials"
      />
      <Brands />
      <AuthorisedVendor
        header="We are listed on the top review sites"
        subHeader="Verified company"
      ></AuthorisedVendor>
      <ServiceQuoteSection
        text={technology.quote.text}
        image={getImage(technology.quote.image)}
      />
    </Layout>
  )
}

export default Technology
